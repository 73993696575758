import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Cowry from "../../../components/body/pages/bill-payments/cowry"
const CowryPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/bill-payments/cowry"}
      title="Cowry Card | Seamlessly Pay for Transportation | Kuda"
      description="Enjoy hassle-free payment for Cowry tap-to-ride transit services through the Kuda app. Simplify your commute with easy contactless payments. Join Kuda!"
    />
    <Cowry />
  </Layout>
)

export default CowryPage
